import Parse from "parse"
import axios from "axios"
import moment from "moment"

//Initialize Parse
Parse.initialize(
  "09NLkiclRtAZmeoGlR9M9W0nuarlh4Wx0fEFM81R",
  "nIw2GpFWThv6hNbKaipdLjflbAxLt560l7j4V3Ef"
)
Parse.serverURL = "https://parseapi.back4app.com/"

const BOOKING_RETURN_URL = "https://flamboyant-shockley-3687fb.netlify.com/"

export async function AddToMailingList(email, name) {}

export async function CreateNewBooking(
  date,
  fullName,
  email,
  phone,
  space,
  guestCount,
  priceEstimate,
  additionalOptions
) {
  const Booking = Parse.Object.extend("Booking")
  const booking = new Booking()

  booking.set("fullName", fullName)
  booking.set("date", date)
  booking.set("email", email)
  booking.set("phoneNumber", phone)
  booking.set("space", space)
  booking.set("guestCount", guestCount)
  booking.set("price", priceEstimate)
  booking.set("additionalOptions", additionalOptions)

  //Save the booking and get the OBJECT ID

  try {
    const savedBooking = await booking.save()
    return {
      success: true,
      bookingId: savedBooking.id,
    }
  } catch (err) {
    return {
      error: err.message,
    }
  }
}

export async function GetBookedDates() {
  const Booking = Parse.Object.extend("Booking")
  const query = new Parse.Query(Booking)

  const today = new Date()

  //Get only the booked appointments, ahead of today
  query.equalTo("status", true)
  query.greaterThanOrEqualTo("date", today)
  query.limit(10000)

  try {
    let results = await query.find()

    //Get dates in string format
    let booked = results.map(item =>
      moment(item.get("date"))
        .startOf("day")
        .toString()
    )

    console.log(booked)
    return {
      success: true,
      booked,
    }
  } catch ({ message }) {
    return {
      success: false,
      error: message,
    }
  }
}
