import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import MainButton from "./main-button"

const Container = styled.footer`
  background: ${colors.main};
`
const MailingListContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #374542;
`
const Title = styled.h2`
  color: white;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 28px;
  }
`
const Input = styled.input`
  width: 100%;
  max-width: 350px;
  background: none;
  border: none;
  border: 1px solid white;
  /* border-bottom: 1px solid white; */
  border-radius: 4px;
  color: white;
  font-size: 16px;
  text-align: center;
  padding: 8px;
  font-style: italic;
  padding: 15px 10px;
  font-family: "Merriweather";

  @media (max-width: 500px) {
    max-width: 300px;
    font-size: 16px;
  }
`
const SubmitBtn = styled.button`
  width: 100%;
  max-width: 350px;
  background: none;
  border: 1px solid white;
  outline: none;
  margin-top: 10px;
  color: white;
  font-size: 16px;
  text-align: center;
  padding: 15px 10px;
  border-radius: 4px;
  font-family: "Work Sans";
  cursor: pointer;

  @media (max-width: 500px) {
    max-width: 300px;
    font-size: 16px;
  }
`
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 20px;
`
const Logo = styled.img`
  width: 60px;
  margin: 0 0 10px 0;
`
const Copyright = styled.p`
  margin: 0 0 10px 0;
  color: #ffffff;
  font-size: 14px;
`
const LinkItemsContainer = styled.div``

const Footer = props => (
  <Container>
    <MailingListContainer>
      <Title>Join our mailing list for special offers</Title>
      <Input placeholder="john@email.com" type="text" />
      <SubmitBtn>Submit</SubmitBtn>
    </MailingListContainer>
    <FooterContainer>
      <Logo src={require("../images/favicon.png")} />
      <Copyright>© Copyright 2020 La Soledad Estate</Copyright>
      <LinkItemsContainer></LinkItemsContainer>
    </FooterContainer>
  </Container>
)

export default Footer
