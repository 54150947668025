import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import { motion, AnimatePresence } from "framer-motion"
import { navigate } from "gatsby"
import Loader from "./loader"

const Container = styled(motion.div)`
  background: rgba(255, 255, 255, 1);
  width: 100%;
  border: 1px solid #e6e6e6;
  box-shadow: 0 9px 22px rgba(173, 173, 173, 0.26);
  border-radius: 3px;
  padding: 20px;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  @media (max-width: 900px) {
    display: block;
  }
`
const EstimateSection = styled.div``
const Break = styled.hr`
  opacity: 0.3;
`
const DetailsSection = styled.div``
const ConfirmButton = styled(motion.button)`
  display: block;
  width: 100%;
  padding: 10px;
  transition: 0.3s ease;
  background: ${props => (props.disabled ? "#D9D0D0" : colors.accent)};
  color: white;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15px;
  font-family: "Work Sans";
  cursor: pointer;
  outline: none;
  border: none;

  :focus {
    outline: none;
  }
`
const Heading = styled.h5`
  color: #5a5a5a;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
`
const Price = styled.h3`
  font-family: "Work Sans";
  margin-bottom: 15px;
  font-weight: 500;
`
const Item = styled.p`
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
`
const ContactInfoSection = styled(motion.section)``
const Label = styled.label`
  color: #5a5a5a;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Work Sans";
`
const Input = styled.input`
  display: block;
  width: 100%;
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  border: 1px solid #e3e0e0;
`
class StickyOrderTotal extends Component {
  state = {
    continuePressed: false,
  }
  render() {
    const {
      guestCount,
      date,
      extras,
      loading,
      fullName,
      email,
      phoneNumber,
    } = this.props
    const { continuePressed } = this.state
    return (
      <Container
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 1 }}
        positionTransition
      >
        {continuePressed && (
          <>
            <EstimateSection>
              <Heading>Your estimate so far</Heading>
              <Price>$15,000TTD</Price>
            </EstimateSection>

            <Break />

            <DetailsSection>
              <Heading>Date</Heading>
              <Item>
                {date ? date.format("dddd MMMM Do, YYYY").toString() : "－"}
              </Item>

              <Heading>Guests</Heading>
              <Item>{guestCount ? `${guestCount} guests` : "－"}</Item>
            </DetailsSection>

            <Break style={{ marginBottom: "10px" }} />
            {date && guestCount && (
              <>
                <ContactInfoSection
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  positionTransition
                >
                  <Label htmlFor="fullName">Full Name</Label>
                  <Input
                    id="fullName"
                    name="fullName"
                    type="text"
                    value={fullName}
                    onChange={this.props.updateInput}
                    placeholder="Jane Doe"
                  />

                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={this.props.updateInput}
                    placeholder="jane@example.com"
                  />
                  <Label htmlFor="phoneNumber">Phone Number</Label>
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    value={phoneNumber}
                    onChange={this.props.updateInput}
                    placeholder="868-718-8625"
                  />
                </ContactInfoSection>
                <Break />
              </>
            )}
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          <>
            {!continuePressed ? (
              <ConfirmButton
                whileHover={{ scale: guestCount ? 1.02 : 1 }}
                disabled={!date || !guestCount}
                onClick={() => this.setState({ continuePressed: true })}
              >
                Continue
              </ConfirmButton>
            ) : (
              <ConfirmButton
                disabled={
                  !date ||
                  !guestCount ||
                  fullName === "" ||
                  email === "" ||
                  phoneNumber.length < 7
                }
                onClick={() => this.props.confirmBooking()}
              >
                Confirm Booking
              </ConfirmButton>
            )}
          </>
        )}
      </Container>
    )
  }
}

export default StickyOrderTotal
