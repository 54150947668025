import React, { Component } from "react"
import Layout from "../components/layout"
import { Break } from "../style/typography"

//NPM
import { FaCheckCircle } from "react-icons/fa"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { Link, navigate } from "gatsby"

//SERVICeS
import { CreateNewBooking } from "../services/parse-bridge"

//COMPONENTS
import Loader from "../components/loader"
import BookingIntroSection from "../components/booking-screen/booking-intro-section"
import HardwarePackageList from "../components/hardware-package-list"
import CheckboxInput from "../components/checkbox-input"
import ExtraOptionsList from "../components/extra-options-list"
import ConfirmCTA from "../components/booking-screen/confirm-cta"
import CustomerInformation from "../components/booking-summary/customer-information"
import { colors } from "../style/colors"

//Containers
const Container = styled.div`
  padding: 50px;

  @media (max-width: 550px) {
    padding: 50px 30px;
  }
`
const Section = styled(motion.section)`
  padding: 20px 0;
`
const SectionHeading = styled.h3`
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 40px;
`
const SectionTitle = styled.h5`
  color: #484848;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 16px;
  margin-bottom: 10px;
`
const Copy = styled.p`
  color: #353535;
  font-size: 18px;
  max-width: 600px;
`
class BookingSummaryPage extends Component {
  constructor(props) {
    super(props)

    this.paymentForm = React.createRef()

    this.state = {
      additionalServices: [],
      loading: false,
      bookingId: null,
    }
  }

  handleExtraItemSelect = title => {
    //Current additional services
    let additionalServices = this.state.additionalServices
    console.log(additionalServices)

    if (additionalServices.includes(title)) {
      //Remove it
      const newArray = additionalServices.filter(el => el !== title)
      this.setState({ additionalServices: newArray })
    } else {
      additionalServices.push(title)
      this.setState({ additionalServices: additionalServices })
    }

    console.log(this.state.additionalServices)
  }

  handleCreateBooking = () => {
    //Begin Loading
    this.setState({ loading: true })

    const { state = [] } = this.props.location
    const { fullName, email, phoneNumber, rawDate, guestCount } = state || {}

    const { additionalServices } = this.state
    //Create the booking in the database
    CreateNewBooking(
      rawDate,
      fullName,
      email,
      phoneNumber,
      "The Estate",
      guestCount,
      15000,
      additionalServices
    ).then(res => {
      if (res.success) {
        //Set the booking ID
        console.log(res.bookingId)

        //TODO: Send Email JS email

        //We no longer load WiPay, just submit the request to the database and take them to a confirmation page
        navigate("/booking-confirmation")
      } else {
        alert(res.error)
      }
    })
  }

  render() {
    const { state = [] } = this.props.location
    const { fullName, date, guestCount, phoneNumber } = state || {}
    const { loading, bookingId } = this.state

    return fullName ? (
      <Layout max={true}>
        <Container>
          <BookingIntroSection />
          <Break />

          <CustomerInformation
            date={date}
            guestCount={guestCount}
            fullName={fullName}
            phoneNumber={phoneNumber}
          />

          <Break />

          <Section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            positionTransition
            ref={this.hardwareRef}
            visible
          >
            <SectionTitle>
              <FaCheckCircle
                size={20}
                style={{ marginBottom: -4 }}
                color={colors.accent}
              />{" "}
              Included in your booking
            </SectionTitle>
            <Copy>
              We’ve partnered with various vendors to provide you with special
              pricing on all equipment needed to host a spectacular outdoor
              event.
            </Copy>
            <SectionTitle>INCLUDES:</SectionTitle>
            <HardwarePackageList />
          </Section>
          <Break />

          <Section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            positionTransition
            visible
          >
            <SectionHeading>
              Extras to make your day even more special
            </SectionHeading>
            <SectionTitle>Other optional services we provide:</SectionTitle>
            <Copy>
              Please note these charges will be added to your quote after we
              talk about them further and confirm
            </Copy>
            <ExtraOptionsList clickHandler={this.handleExtraItemSelect} />
          </Section>
          <Break />
          <Section>
            <ConfirmCTA clickHandler={this.handleCreateBooking} />
            <form
              style={{ marginBottom: 0 }}
              action="https://sandbox.wipayfinancial.com/v1/gateway"
              method="post"
              ref={this.paymentForm}
            >
              <input name="total" type="hidden" value="100" />
              <input name="phone" type="hidden" value={phoneNumber} />
              <input
                name="email"
                type="hidden"
                value="andelhusbands@gmail.com"
              />
              <input name="name" type="hidden" value={fullName} />
              <input name="order_id" type="hidden" value={bookingId} />
              <input
                name="return_url"
                type="hidden"
                value={`https://flamboyant-shockley-3687fb.netlify.com/booking-confirmation?id=${bookingId}`}
              />
              <input name="developer_id" type="hidden" value="1" />
            </form>
            {loading && <Loader />}
          </Section>
        </Container>
      </Layout>
    ) : (
      <Layout max={true}>
        <Container>
          <Link to="/">Return Home</Link>
        </Container>
      </Layout>
    )
  }
}

export default BookingSummaryPage
